<template>
  <div>
    <div><slot /></div>
    <Footer />
    <LazyScrollToTop v-if="scrollButton" />
    <LazyCountrySelector v-if="countrySelector" />
    <ClientOnly>
      <SnackBar />
    </ClientOnly>
    <ClientOnly>
      <ModalCartModal :visibility="showCart" />
    </ClientOnly>
    <ClientOnly>
      <SpinnerLoading />
    </ClientOnly>
  </div>
</template>

<script setup>
useHead({
  // Classes
  bodyAttrs: { class: { margin: 0 } },
})

const { countrySelector } = useCountrySelector()
const { cart } = useCart()

const scrollButton = ref(false)
const showCart = ref(cart)

onMounted(() => {
  let oldValue = 0
  let newValue = 0

  window.onscroll = () => {
    if (window.pageYOffset < 300) {
      scrollButton.value = false
      return
    }

    newValue = window.pageYOffset
    if (oldValue < newValue) {
      scrollButton.value = false
    } else if (oldValue > newValue) {
      scrollButton.value = true
    }
    oldValue = newValue
  }
})
</script>
